/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import {
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  motion,
  useCycle,
} from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";

import { toRelativePageURL } from "../utils/url";

import HeaderInstagram from "./header-instagram";

const HeaderSidebar = () => {
  const {
    query: {
      market,
    },
    pathname,
  } = useRouter();

  const activeLinkClassName = getActiveLinkClassName(pathname);

  const [isOpen, toggle] = useCycle(false, true);

  const sidebar = {
    hide: {
      opacity: 0,
      transition: {
        damping: 10,
        delay: 0.5,
        stiffness: 100,
      },
      transitionEnd: {
        display: "none",
      },
    },
    show: () => ({
      display: "block",
      opacity: 1,

      // Variant must be a function so that the top is computed even if screen orientation changes.
      top: typeof document !== "undefined" ? document.querySelector("header").offsetHeight : 0,
      transition: {
        damping: 10,
        stiffness: 100,
      },
    }),
  };

  const list = {
    hide: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    show: {
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hide: {
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
      y: 50,
    },
    show: {
      opacity: 1,
      transition: {
        y: {
          stiffness: 1000,
          velocity: -100,
        },
      },
      y: 0,
    },
  };

  return (
    <>
      <button
        aria-label="Sidebar"
        className="block md:hidden ml-0 mr-auto outline-none"
        onClick={() => { toggle(); }}
      >
        {isOpen && (
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faTimes}
            size="lg"
          />
        )}
        {!isOpen && (
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faBars}
            size="lg"
          />
        )}
      </button>
      <motion.nav
        animate={isOpen ? "show" : "hide"}
        className="bottom-0 fixed left-0 ls-blur-8 md:hidden px-2 sm:px-5 py-5 right-0"
        initial={false}
        variants={sidebar}
      >
        <motion.ul variants={list}>
          <motion.li
            className="mb-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/", market as string)}
              href={{
                pathname: "/",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/" ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                Home
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="my-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/products", market as string)}
              href={{
                pathname: "/products",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/products") && pathname.indexOf("candle") < 0 ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                Lip Care
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="my-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/products/candles", market as string)}
              href={{
                pathname: "/products/candles",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/products/candles") || pathname.indexOf("candle") >=0 ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                Candles
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="my-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/planet", market as string)}
              href={{
                pathname: "/planet",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/planet" ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                Planet
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="my-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/purpose", market as string)}
              href={{
                pathname: "/purpose",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/partners") || pathname === "/purpose" ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                Causes
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="my-1 text-center"
            variants={item}
          >
            <Link
              as={toRelativePageURL("/people", market as string)}
              href={{
                pathname: "/people",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/people" ? activeLinkClassName : "")}
                onClick={() => { toggle(); }}
              >
                People
              </a>
            </Link>
          </motion.li>
          <motion.li
            className="mt-1 text-center"
            variants={item}
          >
            <HeaderInstagram />
          </motion.li>
        </motion.ul>
      </motion.nav>
    </>
  );
};

const getActiveLinkClassName = (pathname: string) => {
  switch (pathname) {
    case "/products/balance-aromatherapy-cause-candle":
      return "bg-balance-300";
    case "/products/bliss-aromatherapy-cause-candle":
      return "bg-bliss-300";
    case "/products/calm-aromatherapy-cause-candle":
      return "bg-calm-300";
    case "/products/focus-aromatherapy-cause-candle":
      return "bg-focus-300";
    case "/products/sea-salt-citrus-aromatherapy-cause-candle":
      return "bg-sea-salt-citrus-300";

    case "/products/passionfruit-mango-lip-balm":
      return "bg-passionfruit-mango-300";
    case "/products/peach-blossom-lip-balm":
      return "bg-peach-blossom-300";
    case "/products/peppermint-creme-lip-balm":
      return "bg-peppermint-creme-300";
    case "/products/strawberry-creme-organic-lip-balm":
      return "bg-strawberry-creme-300";
    case "/products/vanilla-bean-organic-lip-balm":
      return "bg-vanilla-bean-300";
    case "/products/variety-lip-balm":
      return "bg-variety-300";
    case "/products/very-cherry-lip-balm":
      return "bg-very-cherry-300";

    case "/products/polish-exfoliant-lip-scrub":
      return "bg-polish-exfoliant-300";

    default:
      return "bg-active";
  }
}

export default HeaderSidebar;
