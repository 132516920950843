/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

enum ProductKind {
  BalanceCandle,
  BlissCandle,
  CalmCandle,
  FocusCandle,
  PassionfruitMangoLipBalm,
  PeachBlossomLipBalm,
  PeppermintCremeLipBalm,
  StrawberryCremeOrganicLipBalm,
  SeaSaltCitrusCandle,
  VanillaBeanOrganicLipBalm,
  VarietyLipBalm,
  VeryCherryLipBalm,
  PolishExfoliantLipScrub,
}

export default ProductKind;
