/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import * as d3 from "d3";
import { useEffect } from "react";

const HeaderLogo = ({ smiling }: { smiling: boolean }) => {
  const smile = (smilingWide: boolean) =>
    `M ${smilingWide ? 122 : 125} ${smilingWide ? 46 : 48} ` +
    `A 1 1 0 0 0 ${smilingWide ? 148 : 145 } ${smilingWide ? 46 : 48}`;

  useEffect(() => {
    d3.select("#ls-logo-mouth")
      .attr("fill", "transparent")
      .attr("stroke-width", "5px")
      .transition()
        .attr("d", smile(smiling))
        .attr("stroke", smiling ? "#E53E3E" : "black") // red-600
        .duration(0.5 * 1e3)
        .ease(d3.easeSinInOut);
  });

  return (
    <svg
      className="h-12 sm:h-16 mx-auto"
      id="ls-logo"
      viewBox="0 0 300 65"
    >
      <g transform="translate(0,-292.15623)">
        <path d="m 2.3095651,334.73434 v -33.35937 h 5.4231772 v 28.50586 H 19.855138 v 4.85351 z" />
        <path d="m 44.191077,334.73434 h -4.944662 v -2.80273 q -1.321615,1.86849 -3.098958,2.77995 -1.754558,0.91146 -4.033204,0.91146 -4.580078,0 -7.223307,-3.12175 -2.620443,-3.14453 -2.620443,-8.59049 0,-5.17253 2.688802,-8.33985 2.688802,-3.1901 7.01823,-3.1901 2.643229,0 4.511719,1.11653 1.891276,1.09375 2.757161,3.16732 v -3.5319 h 4.944662 z M 39.383134,323.8652 q 0,-3.34961 -1.572266,-5.2181 -1.549479,-1.86849 -4.352214,-1.86849 -2.802734,0 -4.420573,1.93685 -1.595052,1.91407 -1.595052,5.33204 0,3.44075 1.54948,5.30924 1.549479,1.86849 4.375,1.86849 2.666015,0 4.329427,-2.028 1.686198,-2.02799 1.686198,-5.33203 z" />
        <path d="m 48.54329,330.81507 4.101563,-2.23307 q 0.455729,1.61784 1.686198,2.46094 1.230469,0.8431 3.167318,0.8431 1.663411,0 2.597656,-0.75196 0.957031,-0.77474 0.957031,-2.09635 0,-1.75456 -3.372396,-3.00781 -1.139323,-0.41016 -1.754557,-0.6836 -3.463542,-1.43554 -4.853516,-2.91666 -1.367187,-1.48112 -1.367187,-3.60026 0,-2.89388 2.255859,-4.69401 2.278646,-1.80013 6.015625,-1.80013 2.506511,0 4.283854,0.93424 1.800131,0.91146 2.620443,2.62044 l -3.509114,2.41537 q -0.38737,-1.02539 -1.367188,-1.61784 -0.957031,-0.61524 -2.233073,-0.61524 -1.41276,0 -2.278646,0.6836 -0.843099,0.66081 -0.843099,1.77734 0,1.6862 3.828125,3.07617 1.207683,0.43295 1.86849,0.70638 2.985026,1.1849 4.261068,2.68881 1.298828,1.48112 1.298828,3.71419 0,3.23568 -2.347005,5.08138 -2.347006,1.82292 -6.471355,1.82292 -3.167317,0 -5.263672,-1.1849 -2.096354,-1.1849 -3.28125,-3.62305 z" />
        <path d="m 72.058917,334.73434 v -17.27213 h -3.782553 v -4.32943 h 3.782553 v -8.18034 h 4.944661 v 8.18034 h 3.782552 v 4.32943 h -3.782552 v 17.27213 z" />
        <path d="m 84.591466,303.79033 q 0,-1.25325 0.911458,-2.16471 0.934245,-0.91146 2.210287,-0.91146 1.321615,0 2.1875,0.88867 0.888672,0.86589 0.888672,2.1875 0,1.32162 -0.888672,2.23308 -0.888672,0.88867 -2.1875,0.88867 -1.276042,0 -2.210287,-0.91146 -0.911458,-0.93425 -0.911458,-2.21029 z m 0.660807,30.94401 v -21.60156 h 4.944662 v 21.60156 z" />
        <path d="m 97.237956,334.73434 v -21.60156 h 4.648434 v 3.5319 q 1.48112,-2.23307 3.48633,-3.25846 2.00521,-1.02539 4.8763,-1.02539 2.41537,0 4.10157,0.77474 1.70898,0.75195 2.66601,2.25586 0.56966,0.88867 0.82031,2.07357 0.25066,1.18489 0.25066,4.14713 v 13.10221 h -4.99024 v -11.097 q 0,-4.26107 -1.04818,-5.65104 -1.02539,-1.41276 -3.66862,-1.41276 -1.73177,0 -3.09895,0.63802 -1.34441,0.63802 -2.11915,1.80013 -0.54687,0.77474 -0.77473,2.00521 -0.20508,1.20768 -0.20508,3.87369 v 9.84375 z" />

        <path d="m 164.458,328.05791 4.32943,-2.00521 q 0.61523,2.23308 2.25586,3.41797 1.64062,1.16211 4.19271,1.16211 2.41536,0 3.82812,-1.3444 1.43555,-1.3444 1.43555,-3.60026 0,-2.93945 -4.8763,-5.24089 -0.6836,-0.34179 -1.04818,-0.5013 -5.51432,-2.6888 -7.36003,-4.85351 -1.82291,-2.1875 -1.82291,-5.33203 0,-4.07878 2.75716,-6.60808 2.77995,-2.52929 7.26888,-2.52929 3.69141,0 6.17513,1.43554 2.48372,1.41276 3.44075,4.07878 l -4.23828,2.1875 q -1.0026,-1.57227 -2.21028,-2.30143 -1.1849,-0.72917 -2.73438,-0.72917 -2.1875,0 -3.48633,1.13932 -1.27604,1.13933 -1.27604,3.05339 0,3.00781 5.65104,5.60547 0.43295,0.20507 0.6836,0.31901 4.94466,2.27864 6.79036,4.46614 1.84571,2.16472 1.84571,5.42318 0,4.73958 -2.98503,7.54232 -2.98503,2.77995 -8.06641,2.77995 -4.26106,0 -6.99544,-1.95964 -2.71159,-1.95963 -3.55469,-5.60547 z" />
        <path d="m 196.51855,334.73434 h -4.94466 v -21.60156 h 4.64844 v 3.5319 q 1.48112,-2.25586 3.34961,-3.28125 1.89128,-1.04817 4.44336,-1.04817 2.5293,0 4.19271,1.07096 1.66341,1.04818 2.5293,3.21289 1.70898,-2.23307 3.62304,-3.25846 1.93685,-1.02539 4.39779,-1.02539 2.14193,0 3.73698,0.79752 1.59505,0.77474 2.48372,2.27865 0.41016,0.70638 0.59245,1.6862 0.20508,0.97981 0.20508,2.80273 v 1.73177 13.10221 h -4.94466 v -11.097 q 0,-4.35221 -0.93425,-5.7194 -0.91146,-1.38998 -3.28125,-1.38998 -2.91667,0 -4.16992,1.86849 -1.23047,1.84571 -1.23047,6.49414 v 9.84375 h -4.99023 v -11.097 q 0,-4.01042 -0.97982,-5.5599 -0.95703,-1.54948 -3.32682,-1.54948 -1.45834,0 -2.66602,0.66081 -1.20768,0.66081 -1.91406,1.82292 -0.45573,0.75195 -0.63802,1.8457 -0.1823,1.09375 -0.1823,4.0332 z" />
        <path d="m 231.97429,303.79033 q 0,-1.25325 0.91146,-2.16471 0.93425,-0.91146 2.21029,-0.91146 1.32161,0 2.1875,0.88867 0.88867,0.86589 0.88867,2.1875 0,1.32162 -0.88867,2.23308 -0.88867,0.88867 -2.1875,0.88867 -1.27604,0 -2.21029,-0.91146 -0.91146,-0.93425 -0.91146,-2.21029 z m 0.66081,30.94401 v -21.60156 h 4.94466 v 21.60156 z" />
        <path d="m 244.84863,334.73434 v -35.31901 h 4.94466 v 35.31901 z" />
        <path d="m 277.63835,324.66273 h -17.27213 q 0.18229,3.0306 1.80013,4.73958 1.61783,1.6862 4.30664,1.6862 2.39258,0 3.98763,-1.0026 1.59505,-1.00261 2.77995,-3.23568 l 4.16992,2.32422 q -1.82292,3.25846 -4.51172,4.85351 -2.6888,1.59506 -6.31185,1.59506 -5.19531,0 -8.27149,-3.16732 -3.07617,-3.16732 -3.07617,-8.45378 0,-5.08138 3.16732,-8.33984 3.1901,-3.28125 8.18034,-3.28125 5.2181,0 8.13477,2.98502 2.91666,2.98503 2.91666,8.36263 z m -5.12695,-3.32682 q -0.29623,-2.32422 -1.77735,-3.5319 -1.48112,-1.23047 -4.05598,-1.23047 -2.43816,0 -3.91928,1.18489 -1.48112,1.1849 -2.07356,3.57748 z" />
        <path d="m 280.32715,330.81507 4.10157,-2.23307 q 0.45573,1.61784 1.68619,2.46094 1.23047,0.8431 3.16732,0.8431 1.66341,0 2.59766,-0.75196 0.95703,-0.77474 0.95703,-2.09635 0,-1.75456 -3.3724,-3.00781 -1.13932,-0.41016 -1.75455,-0.6836 -3.46355,-1.43554 -4.85352,-2.91666 -1.36719,-1.48112 -1.36719,-3.60026 0,-2.89388 2.25586,-4.69401 2.27865,-1.80013 6.01563,-1.80013 2.50651,0 4.28385,0.93424 1.80013,0.91146 2.62044,2.62044 l -3.50911,2.41537 q -0.38737,-1.02539 -1.36719,-1.61784 -0.95703,-0.61524 -2.23307,-0.61524 -1.41276,0 -2.27865,0.6836 -0.8431,0.66081 -0.8431,1.77734 0,1.6862 3.82813,3.07617 1.20768,0.43295 1.86849,0.70638 2.98503,1.1849 4.26107,2.68881 1.29882,1.48112 1.29882,3.71419 0,3.23568 -2.347,5.08138 -2.34701,1.82292 -6.47135,1.82292 -3.16732,0 -5.26368,-1.1849 -2.09635,-1.1849 -3.28125,-3.62305 z" />
      </g>
      <circle
        cx="135"
        cy="32"
        fill="transparent"
        id="ls-logo-face"
        r="10"
        stroke="black"
        strokeWidth="5px"
      />
      <path
        d="m 142 27 L 145 20 L 149 20 L 146 27 z"
        fill="black"
        id="ls-logo-ear"
        stroke="black"
        strokeWidth="1px"
      />
      <path
        d={smile(false)}
        fill="transparent"
        id="ls-logo-mouth"
        stroke="black"
        strokeWidth="5px"
      />
    </svg>
  );
};

export default HeaderLogo;
