/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderInstagram = () => (
  <a
    className="inline-block rounded"
    href="https://www.instagram.com/sharelastingsmiles/"
    rel="noopener"
    target="_blank"
  >
    <FontAwesomeIcon
      fixedWidth={true}
      icon={faInstagram}
      size="2x"
    />
  </a>
);

export default HeaderInstagram;
