/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Link from "next/link";
import { useRouter } from "next/router";
import {
  useEffect,
  useState,
} from "react";

import { toRelativePageURL } from "../utils/url";

import HeaderCheckout from "./header-checkout";
import HeaderLogo from "./header-logo";
import HeaderSidebar from "./header-sidebar";

const Header = () => {
  const {
    query: {
      market,
    },
    pathname,
  } = useRouter();

  const [[smiling, scrollY], setState] = useState([false, typeof window !== "undefined" ? window.scrollY : 0]);

  let timeout;
  const handleClick = () => {
    setState([true, scrollY]);

    // Shows a neutral face after smiling for a few seconds.
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState([false, scrollY]);
    }, 2 * 1e3);
  };

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      setState([smiling, window.scrollY]);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollY]);

  // Shows a shadow as soon as user scrolled.
  const shadowClassName = scrollY < 10 ? "shadow-none" : "shadow";

  const themeClassName = getThemeClassName(pathname);
  const activeLinkClassName = getActiveLinkClassName(pathname);

  return (
    <header className={"border-b border-black duration-500 ease-in-out left-0 md:border-none right-0 sticky top-0 transition z-20 " +
      [shadowClassName, themeClassName].join(" ")}
    >
      <div className="max-w-screen-xl mx-auto px-2 sm:px-5">
        <div className="flex flex-row items-center">
          <div className="flex-1">
            <HeaderSidebar />
          </div>
          <div className="flex-1">
            <Link
              as={toRelativePageURL("/", market as string)}
              href={{
                pathname: "/",
                query: { market },
              }}
            >
              <a
                aria-label="Logo"
                className="block"
                onClick={handleClick}
              >
                <HeaderLogo smiling={smiling} />
              </a>
            </Link>
          </div>
          <div className="flex-1">
            <HeaderCheckout />
          </div>
        </div>
      </div>
      <nav className="hidden max-w-screen-lg mx-auto md:block px-5 py-2">
        <ul className="flex flex-row">
          <li className="flex-1 mr-1 text-center">
            <Link
              as={toRelativePageURL("/", market as string)}
              href={{
                pathname: "/",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/" ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                Home
              </a>
            </Link>
          </li>
          <li className="flex-1 mx-1 text-center">
            <Link
              as={toRelativePageURL("/products", market as string)}
              href={{
                pathname: "/products",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/products") && pathname.indexOf("candle") < 0 ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                Lip Care
              </a>
            </Link>
          </li>
          <li className="flex-1 mx-1 text-center">
            <Link
              as={toRelativePageURL("/products/candles", market as string)}
              href={{
                pathname: "/products/candles",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/products/candles") || pathname.indexOf("candle") >=0 ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                Candles
              </a>
            </Link>
          </li>
          <li className="flex-1 mx-1 text-center">
            <Link
              as={toRelativePageURL("/planet", market as string)}
              href={{
                pathname: "/planet",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/planet" ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                Planet
              </a>
            </Link>
          </li>
          <li className="flex-1 mx-1 text-center">
            <Link
              as={toRelativePageURL("/purpose", market as string)}
              href={{
                pathname: "/purpose",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname.startsWith("/partners") || pathname === "/purpose" ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                Causes
              </a>
            </Link>
          </li>
          <li className="flex-1 ml-1 text-center">
            <Link
              as={toRelativePageURL("/people", market as string)}
              href={{
                pathname: "/people",
                query: { market },
              }}
            >
              <a
                className={"block border border-transparent duration-500 ease-in-out font-medium font-serif hover:border-black py-2 rounded-full transition uppercase " +
                  (pathname === "/people" ? activeLinkClassName : "")}
                onClick={handleClick}
              >
                People
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

const getThemeClassName = (pathname: string) => {
  switch (pathname) {
    case "/products/balance-aromatherapy-cause-candle":
      return "bg-balance-100";
    case "/products/bliss-aromatherapy-cause-candle":
      return "bg-bliss-100";
    case "/products/calm-aromatherapy-cause-candle":
      return "bg-calm-100";
    case "/products/focus-aromatherapy-cause-candle":
      return "bg-focus-100";
    case "/products/sea-salt-citrus-aromatherapy-cause-candle":
      return "bg-sea-salt-citrus-100";

    case "/products/passionfruit-mango-lip-balm":
      return "bg-passionfruit-mango-100";
    case "/products/peach-blossom-lip-balm":
      return "bg-peach-blossom-100";
    case "/products/peppermint-creme-lip-balm":
      return "bg-peppermint-creme-100";
    case "/products/strawberry-creme-organic-lip-balm":
      return "bg-strawberry-creme-100";
    case "/products/vanilla-bean-organic-lip-balm":
      return "bg-vanilla-bean-100";
    case "/products/variety-lip-balm":
      return "bg-variety-100";
    case "/products/very-cherry-lip-balm":
      return "bg-very-cherry-100";

    case "/products/polish-exfoliant-lip-scrub":
      return "bg-polish-exfoliant-100";

    default:
      return "bg-default";
  }
}

const getActiveLinkClassName = (pathname: string) => {
  switch (pathname) {
    case "/products/balance-aromatherapy-cause-candle":
      return "bg-balance-300";
    case "/products/bliss-aromatherapy-cause-candle":
      return "bg-bliss-300";
    case "/products/calm-aromatherapy-cause-candle":
      return "bg-calm-300";
    case "/products/focus-aromatherapy-cause-candle":
      return "bg-focus-300";
    case "/products/sea-salt-citrus-aromatherapy-cause-candle":
      return "bg-sea-salt-citrus-300";

    case "/products/passionfruit-mango-lip-balm":
      return "bg-passionfruit-mango-300";
    case "/products/peach-blossom-lip-balm":
      return "bg-peach-blossom-300";
    case "/products/peppermint-creme-lip-balm":
      return "bg-peppermint-creme-300";
    case "/products/strawberry-creme-organic-lip-balm":
      return "bg-strawberry-creme-300";
    case "/products/vanilla-bean-organic-lip-balm":
      return "bg-vanilla-bean-300";
    case "/products/variety-lip-balm":
      return "bg-variety-300";
    case "/products/very-cherry-lip-balm":
      return "bg-very-cherry-300";

    case "/products/polish-exfoliant-lip-scrub":
      return "bg-polish-exfoliant-300";

    default:
      return "bg-active";
  }
}

export default Header;
