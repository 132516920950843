/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { motion } from "framer-motion";
import {
  Router,
  withRouter,
} from "next/router";
import { Component } from "react";

import {
  exception,
  initialize,
  pageview,
} from "../utils/ga";

import Footer from "./footer";
import Header from "./header";
import ShopifyModal from "./modals/shopify-modal";

interface IProps {
  router: Router;
}

class Master extends Component<IProps> {
  public componentDidCatch(error: Error) {
    exception(error.toString());
  }

  public componentDidMount() {
    initialize();
    pageview();
  }

  public render() {
    const {
      children,
      router: {
        route,
      },
    } = this.props;

    return (
      <>
        <style global={true} jsx={true}>
          {`
            @font-face {
              font-display: swap;
              font-family: "Inter VF";
              font-style: normal;
              font-weight: 100 900;
              src: local("Inter VF"),
                url("${require("../public/fonts/inter-roman.var.woff2")}") format("woff2");
            }

            @font-face {
              font-display: swap;
              font-family: "Inter VF";
              font-style: italic;
              font-weight: 100 900;
              src: local("Inter VF"),
                url("${require("../public/fonts/inter-italic.var.woff2")}") format("woff2");
            }

            @font-face {
              font-display: swap;
              font-family: "Jost VF";
              font-style: normal;
              font-weight: 100 900;
              src: local("Jost VF"),
                url("${require("../public/fonts/jost.var.woff2")}") format("woff2");
            }
          `}
        </style>
        <Header />
        <div className="md:px-5">
          <motion.div
            animate={{
              opacity: 1,
              y: 0,
            }}
            className="bg-white max-w-screen-xl mx-auto overflow-hidden md:my-5 md:rounded-lg md:shadow-master"
            exit={{
              opacity: 0,
              y: 0,
            }}
            initial={{
              opacity: 0,
              y: 50,
            }}
            key={route}
          >
            {children}
          </motion.div>
        </div>
        <ShopifyModal />
        <Footer />
      </>
    );
  }
}

export default withRouter(Master);
