/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { Router } from "next/router";

const getThemeClassName = (url: string) => {

  // IMPORTANT: URLs end with `/` because these are not pathnames.
  switch (url) {
    case "/products/balance-aromatherapy-cause-candle/":
      return "bg-balance-100";
    case "/products/bliss-aromatherapy-cause-candle/":
      return "bg-bliss-100";
    case "/products/calm-aromatherapy-cause-candle/":
      return "bg-calm-100";
    case "/products/focus-aromatherapy-cause-candle/":
      return "bg-focus-100";
    case "/products/sea-salt-citrus-aromatherapy-cause-candle/":
      return "bg-sea-salt-citrus-100";

    case "/products/passionfruit-mango-lip-balm/":
      return "bg-passionfruit-mango-100";
    case "/products/peach-blossom-lip-balm/":
      return "bg-peach-blossom-100";
    case "/products/peppermint-creme-lip-balm/":
      return "bg-peppermint-creme-100";
    case "/products/strawberry-creme-organic-lip-balm/":
      return "bg-strawberry-creme-100";
    case "/products/vanilla-bean-organic-lip-balm/":
      return "bg-vanilla-bean-100";
    case "/products/variety-lip-balm/":
      return "bg-variety-100";
    case "/products/very-cherry-lip-balm/":
      return "bg-very-cherry-100";

    case "/products/polish-exfoliant-lip-scrub/":
      return "bg-polish-exfoliant-100";

    default:
      return "bg-default";
  }
};

const handleRouteChange = (url: string) => {
  if (typeof document !== "undefined") {
    const body = document.querySelector("body");
    const themeClassName = getThemeClassName(url);

    if (body !== null) {
      body.classList.remove(...[
        "bg-default",
        "bg-balance-100",
        "bg-bliss-100",
        "bg-calm-100",
        "bg-focus-100",
        "bg-passionfruit-mango-100",
        "bg-peach-blossom-100",
        "bg-peppermint-creme-100",
        "bg-strawberry-creme-100",
        "bg-vanilla-bean-100",
        "bg-variety-100",
        "bg-very-cherry-100",
        "bg-polish-exfoliant-100",
      ]);
      body.classList.add(themeClassName);
    }
  }
};

// We can't use MobX to change the theme because `_document.tsx` is SSRed and
// has no access to `useObserver` or the router's pathname. We have to call the
// handle the very first time the website loads because no `routeChangeComplete`
// event is emitted then.
Router.events.on("routeChangeComplete", handleRouteChange);
if (typeof window !== "undefined") {
  handleRouteChange(window.location.pathname);
}
