/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Link from "next/link";
import { useRouter } from "next/router";

import { toRelativePageURL } from "../utils/url";

const Footer = () => {
  const {
    query: {
      market,
    },
  } = useRouter();

  const copyrightYear = new Date().getFullYear();

  return (
    <footer className="max-w-screen-lg mx-auto px-2 py-10 sm:px-5">
      <div className="flex flex-row flex-wrap items-center">
        <div className="flex-1 m-5 text-center">
          <h3>Sales</h3>
          <a
            className="underline"
            href="mailto:sales@lastingsmiles.org"
          >
            sales@lastingsmiles.org
          </a>
        </div>
        <div className="flex-1 m-5 text-center">
          <h3>Partnerships</h3>
          <a
            className="underline"
            href="mailto:partnerships@lastingsmiles.org"
          >
            partnerships@lastingsmiles.org
          </a>
        </div>
        <div className="flex-1 m-5 text-center">
          <h3>Press</h3>
          <a
            className="underline"
            href="mailto:press@lastingsmiles.org"
          >
            press@lastingsmiles.org
          </a>
        </div>
        <div className="flex-1 m-5 text-center">
          <h3>General Inquiries</h3>
          <a
            className="underline"
            href="mailto:info@lastingsmiles.org"
          >
            info@lastingsmiles.org
          </a>
        </div>
      </div>
      <p className="my-5 text-center">
        PO&nbsp;Box&nbsp;15944, Seattle,&nbsp;WA&nbsp;98115, United States
      </p>
      <p className="leading-loose my-5 text-center">
        <span>&copy; {copyrightYear} Lasting Smiles. </span>
        <Link
          as={toRelativePageURL("/terms", market as string)}
          href={{
            pathname: "/terms",
            query: { market },
          }}
        >
          <a className="underline whitespace-no-wrap">Terms of Use</a>
        </Link>
        <span>&nbsp;&middot;&nbsp;</span>
        <Link
          as={toRelativePageURL("/privacy", market as string)}
          href={{
            pathname: "/privacy",
            query: { market },
          }}
        >
          <a className="underline whitespace-no-wrap">Privacy Policy</a>
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
