/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useObserver } from "mobx-react";
import { useRouter } from "next/router";
import { useContext } from "react";

import {
  IShopifyStore,
  ShopifyContext,
  showShopifyModal,
} from "../stores/shopify";

const HeaderCheckout = () => {
  const router = useRouter();
  const shopifyStore: IShopifyStore = useContext(ShopifyContext) as IShopifyStore;

  return useObserver(() => {
    const lineItemQuantity = shopifyStore.lineItems
      .map((lineItem: ShopifyBuy.LineItem) => lineItem.quantity)
      .reduce((previousValue: number, currentValue: number) =>
        previousValue + currentValue, 0);

    const handleClick = async () => {
      if (lineItemQuantity === 0) {
        await router.push("/products");
      } else {
        showShopifyModal();
      }
    };

    return (
      <button
        aria-label="Checkout"
        className="block ml-auto mr-0 outline-none pr-1 relative"
        onClick={handleClick}
      >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faShoppingCart}
            size="lg"
          />
          {lineItemQuantity > 0 && (
            <motion.span
              animate={{ opacity: 1 }}
              className="-mt-1 absolute bg-red-600 font-medium font-serif px-1 right-0 rounded-sm text-xs text-white top-0"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              {lineItemQuantity}
            </motion.span>
          )}
      </button>
    );
  });
};

export default HeaderCheckout;
